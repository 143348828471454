import React from "react";
import ReactDom from "react-dom";

const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, .7)",
    zIndex: 200001,
} as React.CSSProperties;

const MODAL_STYLES = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 200001,
    borderRadius: "calc(0.3rem - 1px)",
} as React.CSSProperties;

interface CustomModalProps {
    open: boolean;
    backdropClick?: Function;
    children: any;
}

function CustomModal({ open, backdropClick, children }: CustomModalProps) {
    if (!open) {
        return null;
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} onClick={() => (backdropClick ? backdropClick() : "")} />
            <div style={MODAL_STYLES}>{children}</div>
        </>,
        document.getElementById("portal")!
    );
}

export default CustomModal;
