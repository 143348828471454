import React, {useState} from "react";
import Pagination from "react-bootstrap/Pagination";

export function ListPagination({
                                   totPages,
                                   currentPage,
                                   pageClicked,
                               }: {
    totPages: number;
    currentPage: number;
    pageClicked: Function;
}) {
    const [pageArray, setPageArray] = useState<any[]>([]);

    React.useEffect(() => {
        var pageArr: any[] = [];
        if (totPages > 1) {
            if (totPages <= 9) {
                var i = 0;
                while (i < totPages) {
                    pageArr.push(i);
                    i++;
                }
            } else {
                if (currentPage < 5) {
                    pageArr = [0, 1, 2, 3, 4, 5, 6, 7, "", totPages];
                } else if (totPages - currentPage < 4) {
                    pageArr = [
                        0,
                        "",
                        totPages - 8,
                        totPages - 7,
                        totPages - 6,
                        totPages - 5,
                        totPages - 4,
                        totPages - 3,
                        totPages - 2,
                        totPages - 1,
                    ];
                } else {
                    pageArr = [
                        0,
                        "",
                        currentPage - 3,
                        currentPage - 2,
                        currentPage - 1,
                        currentPage,
                        currentPage + 1,
                        currentPage + 2,
                        currentPage + 3,
                        "",
                        totPages,
                    ];
                }
            }
        }
        console.log(pageArr)
        setPageArray(pageArr);
    }, [totPages, currentPage]);

    return (
        <>
            <div style={{marginTop: "15px"}}>
                <Pagination style={{justifyContent: "center"}}>
                    {pageArray.map((ele, ind) => {
                        const toReturn = [];

                        if (ind === 0) {
                            toReturn.push(
                                <Pagination.First
                                    key={"firstpage"}
                                    onClick={
                                        currentPage === 0
                                            ? () => {
                                            }
                                            : () => {
                                                pageClicked(0);
                                            }
                                    }
                                />
                            );

                            toReturn.push(
                                <Pagination.Prev
                                    key={"prevpage"}
                                    onClick={
                                        currentPage === 0
                                            ? () => {
                                            }
                                            : () => {
                                                pageClicked(currentPage - 1);
                                            }
                                    }
                                />
                            );
                        }

                        if (ele === "") {
                            toReturn.push(<Pagination.Ellipsis key={ind}/>);
                        } else {
                            toReturn.push(
                                <Pagination.Item
                                    key={ind}
                                    active={currentPage === ele ? true : false}
                                    onClick={
                                        currentPage === ele
                                            ? () => {
                                            }
                                            : () => {
                                                pageClicked(ele);
                                            }
                                    }
                                >
                                    {ele+1}
                                </Pagination.Item>
                            );
                        }

                        if (ind === pageArray.length - 1) {
                            toReturn.push(
                                <Pagination.Next
                                    key={"nextpage"}
                                    onClick={
                                        currentPage === ele
                                            ? () => {
                                            }
                                            : () => {
                                                pageClicked(currentPage + 1);
                                            }
                                    }
                                />
                            );

                            toReturn.push(
                                <Pagination.Last
                                    key={"lastpage"}
                                    onClick={
                                        currentPage === ele
                                            ? () => {
                                            }
                                            : () => {
                                                pageClicked(ele);
                                            }
                                    }
                                />
                            );
                        }

                        return toReturn;
                    })}
                </Pagination>
            </div>
        </>
    );
}
